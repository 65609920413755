import {
  autocompletion,
  closeBrackets,
  closeBracketsKeymap,
  completionKeymap,
  closeCompletion,
  startCompletion,
  snippetCompletion,
} from '@codemirror/autocomplete';
import { defaultKeymap, history, historyKeymap, indentWithTab } from '@codemirror/commands';
import { javascript, javascriptLanguage } from '@codemirror/lang-javascript';
import { lintKeymap } from '@codemirror/lint';
import { highlightSelectionMatches, searchKeymap } from '@codemirror/search';
import { EditorState } from '@codemirror/state';
import { liquid } from '@codemirror/lang-liquid';
import { html } from '@codemirror/lang-html';
import { dracula } from 'thememirror';
import debounce from 'lodash/debounce';
import {
  bracketMatching,
  defaultHighlightStyle,
  foldGutter,
  foldKeymap,
  indentOnInput,
  syntaxHighlighting,
  HighlightStyle
} from '@codemirror/language'
import {
  crosshairCursor,
  drawSelection,
  dropCursor,
  EditorView,
  highlightActiveLine,
  highlightActiveLineGutter,
  highlightSpecialChars,
  keymap,
  lineNumbers,
  rectangularSelection
} from '@codemirror/view';

const initializeCodeMirror = ({ parent: parentEl, sourceTarget, language, value, onChange, completionOptions }) => {
  const docValue = (() => {
    if (value !== undefined && value !== null) {
      return value;
    } else if (sourceTarget !== undefined) {
      return sourceTarget.value;
    }
    return '';
  })();

  const completionSource = (context) => {
    // match everything behind the editor cursor position
    const word = context.matchBefore(/.*/);

    // continue with a completion only if there is actual text
    if (word.from == word.to || word.text.trim().length <= 0) return null;

    // implement your data fetching
    const variableOptions = completionOptions.variables.filter((opt) => {
      return opt.label.includes(word.text.trim());
    });

    const snippetOptions = completionOptions.snippets
      .filter(({ snippet }) => snippet.includes(word.text.trim()))
      .map(({ snippet, ...opts }) => snippetCompletion(snippet, opts));

    return {
      from: word.from,
      options: [
        ...variableOptions,
        ...snippetOptions,
      ],
      filter: false,
    };
  }

  const debouncedStartCompletion = debounce((view) => {
    startCompletion(view);
  }, 300);

  const customCompletionDisplay = () => {
    return EditorView.updateListener.of(({ view, docChanged }) => {
      if (docChanged) {
        // when a completion is active each keystroke triggers the
        // completion source function, to avoid it we close any open
        // completion inmediatly.
        closeCompletion(view);

        debouncedStartCompletion(view);
      }
    });
  }

  const lang = (() => {
    switch (language) {
      case 'javascript':
        return javascript();
      case 'liquid':
        return liquid();
      case 'html':
        return html();
      default:
        return null;
    }
  })();

  const updateListener = EditorView.updateListener.of((value) => {
    if (sourceTarget) {
      sourceTarget.value = value.state.doc.toString();
    } else {
      onChange(value.state.doc.toString());
    }
  });

  const editor = new EditorView({
    state: EditorState.create({
      doc: docValue,
      extensions: [
        [
      		lineNumbers(),
      		highlightActiveLineGutter(),
      		highlightSpecialChars(),
      		history(),
      		foldGutter(),
      		drawSelection(),
      		dropCursor(),
      		EditorState.allowMultipleSelections.of(true),
      		indentOnInput(),
      		syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
      		bracketMatching(),
      		closeBrackets(),
      		autocompletion({
      		  activateOnTyping: true,
            override: [completionSource],
      		}),
      		customCompletionDisplay(),
      		rectangularSelection(),
      		crosshairCursor(),
      		highlightActiveLine(),
      		highlightSelectionMatches(),
      		keymap.of([
        		...closeBracketsKeymap,
        		...defaultKeymap,
        		...searchKeymap,
        		...historyKeymap,
        		...foldKeymap,
        		...completionKeymap,
        		...lintKeymap,
        		indentWithTab,
      		]),
      		dracula,
      		...(lang ? [lang] : []),
      		updateListener,
    		],
      ],
    }),
    parent: parentEl,
  });

  return editor;
};

export default initializeCodeMirror;
